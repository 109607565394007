import React, {useState} from "react";
import AndroidComponent from "./androidComponent";
import AppDevvelopmentComponent from "./appDevelopmentComponent";
import IosComponent from "./iosApplicationComponent";
const MobileApplicationComponent = (props) =>{

    const {mobileButtonItems} =props
    const [isActive, setisActive] = useState(0);
    return(
            <div className="service-mobile-application-section">
                <div className="service-mobile-application-section-button">
                <div className="full-container">
                    <ul className="service-mobile-application-section-list">
                    {mobileButtonItems &&
              mobileButtonItems.map((item, index) => {
                return (
                  <li
                    key={index}
                    // className={`${
                    //   index === isActive
                    //     ? 'service-mobile-application-section_activeListItem'
                    //     : 'service-mobile-application-section-list-item'
                    // }`}
                  >
                     {/* <span>{item.icon}</span> */}
                    <button
                      onClick={() => {
                        setisActive(index);
                      }}
                      className={`${
                        index === isActive
                          ? 'service-mobile-application-section_activeListItem'
                          : 'service-mobile-application-section-list-item'
                      }`}
                    >
                      <span 
                      className={`${
                        index === isActive
                          ? 'service-mobile-application-section-icon_active'
                          : 'service-mobile-application-section-icon'
                      }`}>
                        {item.icon}
                        </span>
                      <span>{item.text}</span>
                    </button>
                  </li>
                );
              })}
                    </ul>
                    </div>
                </div>
                {isActive === 0 && (
                    <IosComponent />
                )}
                {isActive === 1 && (
                     <AndroidComponent />
                )}
                {isActive === 2 && (
                  <AppDevvelopmentComponent/>
                )}
            </div>
    )
}
export default MobileApplicationComponent
import React from 'react';
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg';
import AppImage from '../../images/appDevelopementImage.png';
import { Link } from 'gatsby';
const AppDevvelopmentComponent = () => {
  return (
    <div className='ios-section'>
      <div className='full-container'>
        <div className='row'>
          <div className='col-md-8'>
            <div className='home-section-textIcone'>
              <TitleIcone className='home-section-titleIcone' />
              <p className='ios-section-title'>
                Cross-Platform App Development
              </p>
            </div>
            <div className='ios-section-wrapper'>
              <p className='ios-section-description'>
                Develop the software once and deploy it everywhere. Our
                cross-platform custom app development allows you to target
                multiple audiences, beat tight deadlines, and widen your reach.
                Are you looking for an Android app, iOS app, and web app with
                the same features? No problem!
              </p>
              <p className='ios-section-description'>
                {' '}
                Cross-platform app development comes with several benefits.
                First, they’re a cost-cutting and time-saving solution, which
                means you can speed up the development lifecycle and launch your
                custom cross-platform application sooner. You will also get full
                functionality, easy and fast implementation, and cost-effective
                production.
              </p>
            </div>
          </div>
          <div className='col-md-4  ios-section-col'>
            <img src={AppImage} className='ios-section-image' />
          </div>
        </div>
      </div>
      <div className='ios-section-text-section'>
        <div className='full-container'>
          <div className='ios-section-requirements app'>
            <p className='ios-section-requirements-title'>Requirements</p>
            <p className='ios-section-requirements-description'>
              Through various meetings we will make sure we have defined clear
              objectives on what we plan to achieve. We will have a clear idea
              on app functionality and features and be a new face on the market.{' '}
            </p>
            <p className='ios-section-requirements-description'>
              Another important part is researching our competitors. We will
              have a thorough analysis of what is already out there, the
              innovations being brought up everyday and make sure to be updated
              with what the users are expecting.
            </p>
          </div>
          <div className='ios-section-propertype'>
            <p className='ios-section-requirements-title'>Prototype</p>
            <p className='ios-section-requirements-description'>
              When designing a cross-platform application we are clear on
              considering designing for multiple screen sizes and resolutions.
              Our designers will create different layouts or versions of the UI
              for each platform.
            </p>
            <p className='ios-section-requirements-description'>
              They also keep in mind how users will use the app in each
              platform. They will make sure to respect specifications of each
              platform.
            </p>
          </div>
          <div className='ios-section-developement'>
            <p className='ios-section-requirements-title'>Development</p>
            <p className='ios-section-requirements-description'>
              It is crucial for our development process to choose the right
              framework, the right tools. Our front-end and back-end developers
              will do their research and choose the right ones that meet your
              needs.
            </p>
          </div>
          <div className='ios-section-propertype'>
            <p className='ios-section-requirements-title'>Testing</p>
            <p className='ios-section-requirements-description'>
              After having built the cross-platform application it is essential
              to test and debug it to make sure everything is working perfectly.
              Since it is going to be used in multiple platforms, the testing is
              going to be handled in different platform in order to make sure
              there are no problems.
            </p>
            <p className='ios-section-requirements-description'>
              In order to perform a successful cross-platform application
              testing is important to understand the targeted audience, how they
              will use the application, and what requirements we need to
              prioritize.
            </p>
            <p className='ios-section-requirements-description'>
              Another course of action will be to identify the most important
              test scenarios, utilize automation so we can identify issues as
              they may appear on specific devices, browser versions, OS
              configurations, in terms of usability, consistency, UI, and
              performance bugs.
            </p>
          </div>
          <div className='ios-section-developement support'>
            <p className='ios-section-requirements-title'>
              Maintenance and support
            </p>
            <p className='ios-section-requirements-description'>
              Last but not least, maintenance management in UpTech Sh.P.K will
              take the necessary steps to ensure the app is bug-free, as we
              focus on multiple aspects, like fixing potential errors, existing
              features, updating functionalities in order to match user’s
              expectations.
            </p>
            <p className='ios-section-requirements-description'>
              We will continuously perform complete app monitoring, facilitate
              latest upgrades, redesign UI/UX, and provide optimum security.
            </p>
          </div>
        </div>
      </div>
      <div className='ios-section-link-section'>
        <Link className='ios-section-link' to='/contact'>
          Contact Us
        </Link>
      </div>
    </div>
  );
};
export default AppDevvelopmentComponent;

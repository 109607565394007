import React from 'react';
import SolutionsIcone from '../../../static/assets/projectSolutions/solutionsIcone.svg';
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg';
import ThumbUp from '../../../static/assets/partner/thumb_up.svg';
import IOSIcone from '../../../static/assets/mobileApplication/ios.svg';
import AndroidIcon from '../../../static/assets/mobileApplication/android .svg';
import CrossIcon from '../../../static/assets/mobileApplication/cross.svg';
const SolutionsComponent = props => {
  const {
    solutionsTitle,
    userExperienceTitle,
    userExperienceDescription,
    webApplicationTitle,
    webApplicationDescription,
    addFeaturesTitle,
    addFeaturesDescription,
    bashTitle,
    bashDescription,
    isBashProject,
    isParamount,
    webAppliationTitle,
    webAppliationDescription,
    isWebApplication,
    webApplicationSubtitle,
    isWorkPage,
    isMobileApplication,
    isProject,
    isRacuten,
    racutenTitle,
    racutenDescription,
  } = props;
  return (
    <div
      className={`solutions-section ${
        isWebApplication ? 'web-application-section' : ''
      }`}
    >
      <div className='full-container'>
        <div className='home-section-textIcone'>
          <TitleIcone className='home-section-titleIcone' />
          <h2
            className={`ourWork-project-section-text-title ${
              isWebApplication ? 'web-application-section-title' : ''
            }`}
          >
            {solutionsTitle}
          </h2>
        </div>
        {!isProject && (
          <p
            className={`web-application-section-text ${
              isMobileApplication ? 'mobile-application-section' : ''
            }`}
          >
            {webApplicationSubtitle}
          </p>
        )}
        <div
          className={`solutions-section-text first ${
            isWebApplication ? 'web-application-section-wrapper' : ''
          }`}
        >
          <div className='row'>
            <div className={` ${isWebApplication ? 'col-md-2' : 'col-md-4'}`}>
              <div
                className={`solutions-section-icone ${
                  isWebApplication ? 'web-application-section-icon' : ''
                }`}
              >
                {isWebApplication && <ThumbUp />}
                {isWorkPage && <SolutionsIcone />}
                {isMobileApplication && <IOSIcone />}
              </div>
            </div>
            <div className={`${isWebApplication ? 'col-md-10' : 'col-md-8 '}`}>
              <p
                className={`solutions-section-subtitle ${
                  isWebApplication ? 'web-application-section-subtitle' : ''
                }`}
              >
                {userExperienceTitle}
              </p>
              <p
                className={`solutions-section-description-text  ${
                  isWebApplication ? 'web-application-section-description' : ''
                }`}
                dangerouslySetInnerHTML={{
                  __html: userExperienceDescription?.replace(
                    'Motomtech’s',
                    'UpTech’s Sh.p.k'
                  ),
                }}
              ></p>
            </div>
          </div>
        </div>
        {!isParamount && (
          <div>
            <div
              className={`solutions-section-text  ${
                isWebApplication ? 'web-application-section-wrapper' : ''
              }`}
            >
              <div className='row'>
                <div
                  className={` ${isWebApplication ? 'col-md-2' : 'col-md-4'}`}
                >
                  <div
                    className={`solutions-section-icone ${
                      isWebApplication ? 'web-application-section-icon' : ''
                    }`}
                  >
                    {isWebApplication && <ThumbUp />}
                    {isWorkPage && <SolutionsIcone />}
                    {isMobileApplication && <AndroidIcon />}
                  </div>
                </div>
                <div
                  className={`${isWebApplication ? 'col-md-10' : 'col-md-8 '}`}
                >
                  <p
                    className={`solutions-section-subtitle ${
                      isWebApplication ? 'web-application-section-subtitle' : ''
                    }`}
                  >
                    {webApplicationTitle}
                  </p>
                  <p
                    className={`ssolutions-section-description-text  ${
                      isWebApplication
                        ? 'web-application-section-description'
                        : ''
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: webApplicationDescription?.replace(
                        'Motomtech',
                        'UpTech Sh.p.k'
                      ),
                    }}
                  ></p>
                </div>
              </div>
            </div>
            <div
              className={`solutions-section-text  ${
                isWebApplication ? 'web-application-section-wrapper' : ''
              }`}
            >
              <div className='row'>
                <div
                  className={` ${isWebApplication ? 'col-md-2' : 'col-md-4'}`}
                >
                  <div
                    className={`solutions-section-icone ${
                      isWebApplication ? 'web-application-section-icon' : ''
                    }`}
                  >
                    {isWebApplication && <ThumbUp />}
                    {isWorkPage && <SolutionsIcone />}
                    {isMobileApplication && <CrossIcon />}
                  </div>
                </div>
                <div
                  className={`${isWebApplication ? 'col-md-10' : 'col-md-8 '}`}
                >
                  <p
                    className={`solutions-section-subtitle ${
                      isWebApplication ? 'web-application-section-subtitle' : ''
                    }`}
                  >
                    {addFeaturesTitle}
                  </p>
                  <p
                    className={`solutions-section-description-text  ${
                      isWebApplication
                        ? 'web-application-section-description'
                        : ''
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: addFeaturesDescription?.replace(
                        'Motomtech',
                        'UpTech Sh.p.k'
                      ),
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        )}
        {isRacuten && (
          <div
            className={`solutions-section-text ${
              isWebApplication ? 'web-application-section-wrapper' : ''
            }`}
          >
            <div className='row'>
              <div className={` ${isWebApplication ? 'col-md-2' : 'col-md-4'}`}>
                <div
                  className={`solutions-section-icone ${
                    isWebApplication ? 'web-application-section-icon' : ''
                  }`}
                >
                  {isWebApplication && <ThumbUp />}
                  {isWorkPage && <SolutionsIcone />}
                </div>
              </div>
              <div
                className={`${isWebApplication ? 'col-md-10' : 'col-md-8 '}`}
              >
                <p
                  className={`solutions-section-subtitle ${
                    isWebApplication ? 'web-application-section-subtitle' : ''
                  }`}
                >
                  {racutenTitle}
                </p>
                <p
                  className={`solutions-section-description-text ${
                    isWebApplication
                      ? 'web-application-section-description'
                      : ''
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: racutenDescription?.replace(
                      'Motomtech',
                      'UpTech Sh.p.k'
                    ),
                  }}
                ></p>
              </div>
            </div>
          </div>
        )}
        {isBashProject && (
          <div
            className={`solutions-section-text ${
              isWebApplication ? 'web-application-section-wrapper' : ''
            }`}
          >
            <div className='row'>
              <div className={` ${isWebApplication ? 'col-md-2' : 'col-md-4'}`}>
                <div
                  className={`solutions-section-icone ${
                    isWebApplication ? 'web-application-section-icon' : ''
                  }`}
                >
                  {isWebApplication && <ThumbUp />}
                  {isWorkPage && <SolutionsIcone />}
                </div>
              </div>
              <div
                className={`${isWebApplication ? 'col-md-10' : 'col-md-8 '}`}
              >
                <p
                  className={`solutions-section-subtitle ${
                    isWebApplication ? 'web-application-section-subtitle' : ''
                  }`}
                >
                  {bashTitle}
                </p>
                <p
                  className={`solutions-section-description-text ${
                    isWebApplication
                      ? 'web-application-section-description'
                      : ''
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: bashDescription?.replace(
                      'Motomtech',
                      'UpTech Sh.p.k'
                    ),
                  }}
                ></p>
              </div>
            </div>
          </div>
        )}
        {isWebApplication && (
          <div
            className={`solutions-section-text  ${
              isWebApplication ? 'web-application-section-wrapper' : ''
            }`}
          >
            <div className='row'>
              <div className={` ${isWebApplication ? 'col-md-2' : 'col-md-4'}`}>
                <div
                  className={`solutions-section-icone ${
                    isWebApplication ? 'web-application-section-icon' : ''
                  }`}
                >
                  {isWebApplication && <ThumbUp />}
                  {isWorkPage && <SolutionsIcone />}
                </div>
              </div>
              <div
                className={`${isWebApplication ? 'col-md-10' : 'col-md-8 '}`}
              >
                <p
                  className={`solutions-section-subtitle ${
                    isWebApplication ? 'web-application-section-subtitle' : ''
                  }`}
                >
                  {webAppliationTitle}
                </p>
                <p
                  className={`solutions-section-description-text  ${
                    isWebApplication
                      ? 'web-application-section-description'
                      : ''
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: webAppliationDescription?.replace(
                      'Motomtech',
                      'UpTech Sh.p.k'
                    ),
                  }}
                ></p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default SolutionsComponent;

import React from 'react';
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg';
import AndroidImage from '../../images/androidImage.png';
import { Link } from 'gatsby';
const AndroidComponent = props => {
  return (
    <div className='ios-section '>
      <div className='full-container'>
        <div className='row'>
          <div className='col-md-8'>
            <div className='home-section-textIcone'>
              <TitleIcone className='home-section-titleIcone' />
              <p className='ios-section-title'>
                Custom Android Application Development
              </p>
            </div>
            <div className='ios-section-wrapper'>
              <p className='ios-section-description'>
                UpTech Sh.P.K has experience working with a diverse clientele in
                Android application development ranging from aspiring
                entrepreneurs' early-stage ventures to large established
                enterprises.
              </p>
              <p className='ios-section-description'>
                UpTech’s Sh.P.K Android app development teams follow industry
                standards paired with agile processes and adopt the best
                application development practices and proven methodologies.
              </p>
              <p className='ios-section-description'>
                Utilizing a myriad of Android development tools along with
                harnessing the maximum potential of Android SDK, our developers
                ensure the successful completion of any project within client
                development timelines.
              </p>
            </div>
          </div>
          <div className='col-md-4 ios-section-col'>
            <img src={AndroidImage} className='ios-section-image' />
          </div>
        </div>
      </div>
      <div className='ios-section-text-section '>
        <div className='full-container'>
          <div className='ios-section-requirements android'>
            <p className='ios-section-requirements-title'>Requirements</p>
            <p className='ios-section-requirements-description'>
              Beginning with a series of in-depth discussions that allow us to
              clearly comprehend your needs, we offer comprehensive software
              development services. In order to ensure that your software is
              targeted towards meeting your business goals, we build a thorough
              understanding of your organization through this process.{' '}
            </p>
            <p className='ios-section-requirements-description'>
              Then, we provide a software solution that blatantly satisfies all
              goals. We work with you through a series of meetings to get a
              clear understanding of your idea and offer the best way to execute
              it.
            </p>
          </div>
          <div className='ios-section-propertype'>
            <p className='ios-section-requirements-title'>Prototype</p>
            <p className='ios-section-requirements-description'>
              Android has already established guidelines that need to be taken
              into consideration when creating and designing a new application.
              Also there is not a determination on models of screens/devices so
              the design should definitely match these specifications.{' '}
            </p>
            <p className='ios-section-requirements-description'>
              {' '}
              Our UI/UX designers know how to perfectly combine the necessary
              components needed for an android customized application with
              impressive innovations.
            </p>
          </div>
          <div className='ios-section-developement'>
            <p className='ios-section-requirements-title'>Development</p>
            <p className='ios-section-requirements-description'>
              {' '}
              Our skilled team of android developers recognizes special
              characteristics of the development of android applications that
              can result in the creation of cutting-edge, custom Android
              applications that can be utilized successfully in cross-platform
              architecture like Windows, Mac OS, and Linux.{' '}
            </p>
            <p className='ios-section-requirements-description'>
              Our team of Android developers chooses the appropriate technology
              and develops a concept for the creation of the custom android
              application development after thoroughly analyzing the major
              aspects involved and the numerous scenarios in which the custom
              android applications will be utilized.
            </p>
          </div>
          <div className='ios-section-propertype'>
            <p className='ios-section-requirements-title'>Testing</p>
            <p className='ios-section-requirements-description'>
              Testing our app is an integral part of the app development
              process. By running tests against your app consistently, we verify
              your app’s correctness, functional behavior, and usability before
              releasing it publicly.
            </p>
            <p className='ios-section-requirements-description'>
              By testing carefully the app, will give us advantages on rapid
              feedback on failures, safer code refactoring, allowing us to
              optimize code without worrying about regressions, and stable
              development velocity, helping you minimize technical debt.
            </p>
          </div>
          <div className='ios-section-developement support'>
            <p className='ios-section-requirements-title'>
              Maintenance and support
            </p>
            <p className='ios-section-requirements-description'>
              UpTech Sh.P.K gives the necessary importance to maintenance and
              support of the application. We prioritize on-going support and
              always aim for customer satisfaction. This minimizes uninstalls,
              helps us deliver better user experiences, and keep up with the
              competition.
            </p>
            <p className='ios-section-requirements-description'>
              We will try to add new feature updates by receiving feedback from
              users and implementing the necessary changes, and ensuring app
              support with new hardware and software.
            </p>
            <p className='ios-section-requirements-description'>
              Furthermore, we will continuously keep the user interface updated
              and fix any bugs in a timely manner. Our QA’s will monior the
              performance continuously and also stay updated with the analytics
              report.
            </p>
            <p className='ios-section-requirements-description'>
              We have the confidence to take on the most difficult tasks and
              produce exceptional Android apps that you will adore thanks to our
              experience in the Android Market Place, our great designers, and
              our highly skilled Android app development team.
            </p>
          </div>
        </div>
      </div>
      <div className='ios-section-link-section'>
        <Link className='ios-section-link' to='/contact'>
          Contact Us
        </Link>
      </div>
    </div>
  );
};
export default AndroidComponent;

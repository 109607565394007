import { Link } from 'gatsby';
import React from 'react';
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg';
import IosImage from '../../images/ios.png';
const IosComponent = props => {
  return (
    <div className='ios-section'>
      <div className='full-container'>
        <div className='row'>
          <div className='col-md-8'>
            <div className='home-section-textIcone'>
              <TitleIcone className='home-section-titleIcone' />
              <p className='ios-section-title'>
                Custom iOS Application Development
              </p>
            </div>
            <div className='ios-section-wrapper'>
              <p className='ios-section-description'>
                We excel at developing cutting-edge iPhone and iPad applications
                tailored to suit client requirements. We have worked with
                industry-specific solutions and customized apps for iOS.
              </p>
              <p className='ios-section-description'>
                UpTech’s Sh.P.K iOS software development teams are experts in
                various areas, including games, finance applications, telecom,
                educational applications, and mobile solutions for publishers.
              </p>
            </div>
          </div>
          <div className='col-md-4  ios-section-col'>
            <img src={IosImage} className='ios-section-image' />
          </div>
        </div>
      </div>
      <div className='ios-section-text-section'>
        <div className='full-container'>
          <div className='ios-section-requirements'>
            <p className='ios-section-requirements-title'>Requirements</p>
            <p className='ios-section-requirements-description'>
              One of the most important processes of Custom iOS Application
              Development is to thoroughly research market needs. It is
              imperative we understand iOS users, the innovations we can include
              so we can better improve their experience. Our goal is to stay up
              to date and fresh.{' '}
            </p>
            <p className='ios-section-requirements-description'>
              If we are looking to create an iOS Application we aim to come up
              with the best solutions and recommendations. If you have an idea
              we will make sure to strategize the best course of action to bring
              it to life.
            </p>
          </div>
          <div className='ios-section-propertype'>
            <p className='ios-section-requirements-title'>Prototype</p>
            <p className='ios-section-requirements-description'>
              One of the most important processes of Custom iOS Application
              Development is to thoroughly research market needs. It is
              imperative we understand iOS users, the innovations we can include
              so we can better improve their experience. Our goal is to stay up
              to date and fresh.{' '}
            </p>
            <p className='ios-section-requirements-description'>
              If we are looking to create an iOS Application we aim to come up
              with the best solutions and recommendations. If you have an idea
              we will make sure to strategize the best course of action to bring
              it to life.
            </p>
          </div>
          <div className='ios-section-developement'>
            <p className='ios-section-requirements-title'>Development</p>
            <p className='ios-section-requirements-description'>
              The first thing we do as we start to develop our iOS app is decide
              on the right technology architecture features, technology stack
              and priority of development stages.{' '}
            </p>
            <p className='ios-section-requirements-description'>
              Our front-end development codes will be directly linked to users.
              They will make sure the code is flawless and seamlessly
              synchronized.
            </p>
            <p className='ios-section-requirements-description'>
              Meanwhile our back-end developers will make possible the best
              server’s responses to user requests so that the user experience
              will leave a lasting impression. Their main focus is to make the
              application responsive, fast and performative.
            </p>
          </div>
          <div className='ios-section-propertype'>
            <p className='ios-section-requirements-title'>Testing</p>
            <p className='ios-section-requirements-description'>
              We aim to test the performance of the application in the early
              stages. This way we will have a clearer view on the speed and
              response time, application’s scalability, not crashing with
              maximum user presence, and stability under different workloads.
            </p>
            <p className='ios-section-requirements-description'>
              UpTech's Sh.P.K QA try to embody user’s exact vision into the
              final product. By thoroughly testing the final product we will be
              able make sure you get the result you want.
            </p>
          </div>
          <div className='ios-section-developement support'>
            <p className='ios-section-requirements-title'>
              Maintenance and support
            </p>
            <p className='ios-section-requirements-description'>
              {' '}
              Our team offers a complete package of app maintenance solutions
              that include bug tracking and fixes by continuously monitoring
              tests, and running index auditing to ensure the app remains
              bug-free.
            </p>
            <p className='ios-section-requirements-description'>
              Another way we maintain and support our application is adding
              feature enhancements according to market new trends as well as
              develop new modules to enhance the app’s UX with the provision for
              re-architecting or code re-factoring the mobile application.
            </p>
          </div>
        </div>
        <div className='ios-section-link-section'>
          <Link className='ios-section-link' to='/contact'>
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
};
export default IosComponent;

import React from "react";
import SolutionsComponent from "../component/OurWork/solutionsComponent";
import { graphql } from 'gatsby'; 
import FooterComponent from '../component/footerComponent'   
import ServicesHomeComponen from "../component/Services/servicesHomeComponent";
import MobileImage from '../images/mobile_application_banner-min.jpg'
import Layout from "../component/layout";
import ServicesImage from '../images/services_banner-min.jpg'
import MobileApplicationComponent from "../component/Services/mobileApplicationComonent";
import AndroidIcon from '../../static/assets/androidIcon.svg'
import IosIcon from '../../static/assets/iosIcons.svg'
import AppDevelopmentIcon from '../../static/assets/appDevelopment.svg'
const mobileButtonItems = [
  {
    text:"iOS Application Development",
    icon:<IosIcon className="service-mobile-application-section-icon-button"/>,
  },
    // <AndroidIcon/>,
    {
      text:"Android Application Development",
      icon:<AndroidIcon className="service-mobile-application-section-icon-button"/>,
    },
    {
      text:'Cross-Platform App Development',
      icon:<AppDevelopmentIcon className="service-mobile-application-section-icon-button"/>
    }
  // "Cross-Platform App Development"
]
const MobileApplication = (props) =>{
    const{
        data:{
          wpPage:{
            seo,
            serviciesHeader :{
              servicesHomeDescription,
              servicesHomeTitle,
            },
            // // projectSolutionsSection :{
            // //     projectSolutionsNewFeaturesDescription,
            // //     projectSolutionsNewFeaturesTitle,
            // //     projectSolutionsNextLevelDescription,
            // //     projectSolutionsNextLevelTitle,
            // //     projectSolutionsTitle,
            // //     projectSolutionsUserExperienceDescription,
            // //     projectSolutionsUserExperienceTitle,
            // //     projectSolutionsWebApplicationDescription,
            // //     projectSolutionsWebApplicationTitle,
            // //     projectWebSectionOptimisationDescription,
            // //     projectWebSectionOptimisationTitle,
            // //   }
          },
        },
        pageContext: { title },
    } = props;       
    return(
      <Layout seo={seo} title={title}>
    <ServicesHomeComponen
     servicesBackground={ServicesImage}
     servicesHomeTitle={servicesHomeTitle}
     servicesDescription={servicesHomeDescription}
     />
     <MobileApplicationComponent
     mobileButtonItems={mobileButtonItems}
     />
        {/* <SolutionsComponent
        webApplicationSubtitle="We create modern, mobile experiences that transform businesses, 
        brands and consumer interactions. Our teams are laser focused on designing, 
        coding, testing and deploying unique, feature-rich web and mobile applications. With in-depth knowledge of native iOS, Android, 
        and cross-platform application development, our developers ensure that your concept is turned into a high-quality,
         attractively designed, user-friendly, and entertaining mobile application."
        solutionsTitle={projectSolutionsTitle}
        userExperienceTitle={projectSolutionsUserExperienceTitle}
        userExperienceDescription={projectSolutionsUserExperienceDescription}
        webApplicationTitle={projectSolutionsWebApplicationTitle}
        webApplicationDescription={projectSolutionsWebApplicationDescription}
        addFeaturesTitle={projectSolutionsNewFeaturesTitle}
        addFeaturesDescription={projectSolutionsNewFeaturesDescription}
        bashTitle={projectSolutionsNextLevelTitle}
        bashDescription={projectSolutionsNextLevelDescription}
        webAppliationTitle={projectWebSectionOptimisationTitle}
        webAppliationDescription={projectWebSectionOptimisationDescription}
        isMobileApplication={true}
        /> */}
        <FooterComponent/>
        </Layout>
    )
}
export const query = graphql`
  query {
wpPage(slug: {eq: "mobile-application"}) {
  seo {
    metaDesc
    title
  }
  serviciesHeader {
    servicesHomeDescription
    servicesHomeTitle
  }
    projectSolutionsSection {
        projectSolutionsNewFeaturesDescription
        projectSolutionsNewFeaturesTitle
        projectSolutionsNextLevelDescription
        projectSolutionsNextLevelTitle
        projectSolutionsTitle
        projectSolutionsUserExperienceDescription
        projectSolutionsUserExperienceTitle
        projectSolutionsWebApplicationDescription
        projectSolutionsWebApplicationTitle
        projectWebSectionOptimisationDescription
        projectWebSectionOptimisationTitle
      }
}
}
`;
export default MobileApplication